import React from 'react'
import { graphql } from 'gatsby'

import Link from '../components/link'
import Layout from '../components/layout'
import Reveal from '../components/reveal'

export default ({ data, location }) => {
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const { previous, next } = data

    return (
        <Layout
            location={location}
            description={post.frontmatter.description || post.excerpt}
            title={siteTitle}
        >
            <article itemScope itemType="http://schema.org/Article">
                <section className="section section-hero">
                    <Reveal className="wrap">
                        <div className="label">OUR WORK</div>
                        <h1 className="title">{post.frontmatter.title}</h1>
                        <div
                            className="container"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                            itemProp="articleBody"
                        />
                    </Reveal>
                </section>
                <section className="section" style={{ background: '#EEE' }}>
                    <Reveal from="bottom" className="wrap">
                        <img
                            width="2130"
                            height="1506"
                            src={post.frontmatter.image1.publicURL}
                            alt={post.frontmatter.title}
                            title={post.frontmatter.title}
                        />
                    </Reveal>
                </section>
                <section className="section">
                    <Reveal className="wrap">
                        <h2 className="title">Process</h2>
                        <div
                            className="container"
                            dangerouslySetInnerHTML={{
                                __html: post.frontmatter.process
                            }}
                        />
                    </Reveal>
                </section>
                <section className="section" style={{ background: '#EEE' }}>
                    <Reveal from="bottom" className="wrap">
                        <img
                            width="2130"
                            height="1506"
                            src={post.frontmatter.image2.publicURL}
                            alt={post.frontmatter.title}
                            title={post.frontmatter.title}
                        />
                    </Reveal>
                </section>
            </article>
            <section className="section section-more section-grey">
                <Reveal className="wrap">
                    <h1 className="label">More projects</h1>
                    <div className="more-items">
                        {previous && (
                            <div className="project-text">
                                <h2 className="project-title">
                                    <Link
                                        to="/blog"
                                        to={previous.fields.slug}
                                        rel="prev"
                                    >
                                        {previous.frontmatter.title}
                                    </Link>
                                </h2>
                                <p>{previous.frontmatter.description}</p>
                            </div>
                        )}
                        {next && (
                            <div className="project-text">
                                <h2 className="project-title">
                                    <Link
                                        to="/blog"
                                        to={next.fields.slug}
                                        rel="prev"
                                    >
                                        {next.frontmatter.title}
                                    </Link>
                                </h2>
                                <p>{next.frontmatter.description}</p>
                            </div>
                        )}
                    </div>
                </Reveal>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
    query WorkPostBySlug(
        $id: String!
        $previousPostId: String
        $nextPostId: String
    ) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                color
                text_color
                process
                image1 {
                    publicURL
                }
                image2 {
                    publicURL
                }
            }
        }
        previous: markdownRemark(id: { eq: $previousPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
                description
            }
        }
        next: markdownRemark(id: { eq: $nextPostId }) {
            fields {
                slug
            }
            frontmatter {
                title
                description
            }
        }
    }
`
